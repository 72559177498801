<template>
  <div>
    <v-toolbar flat class="mb-2">
      <v-btn-toggle dense v-model="filterType" class="mr-4">
        <v-btn text>mit Praktika</v-btn>
        <v-btn text>ohne Praktika</v-btn>
        <v-btn text>ignoriert</v-btn></v-btn-toggle
      >
      <SchoolYearPicker outlined text v-model="schoolYear" />

      <v-spacer />
      <v-text-field
        single-line
        dense
        hide-details
        label="Suche"
        v-model="search"
        append-icon="mdi-magnify"
        clearable
      ></v-text-field>
    </v-toolbar>
    <v-card>
      <v-system-bar>{{ title }}</v-system-bar>
      <v-data-table
        :loading="loading"
        :items="itemsFiltered"
        :headers="headers"
        disable-filtering
        disable-sort
        disable-pagination
        hide-default-footer
      >
        <template v-slot:item.student="{ item }">
          <PersonItem v-if="item.student" :value="item.student" />
        </template>
        <template v-slot:item.institution="{ item }">
          <InstitutionItem v-if="item.institution" :value="item.institution" />
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-right">
            <v-btn
              class="mr-2"
              small
              text
              outlined
              color="danger"
              v-if="item.institution"
              @click="removeStudent(item)"
              >Platzierung entfernen</v-btn
            >
            <v-btn
              class="mr-2"
              small
              text
              outlined
              color="error"
              @click="unIgnore(item)"
              v-if="item.ignored"
              >nicht ignorieren</v-btn
            >
            <v-btn
              class="mr-2"
              small
              text
              outlined
              color="error"
              @click="ignore(item.student)"
              v-else-if="!item.institution"
              >ignorieren</v-btn
            >
            <v-btn
              small
              text
              outlined
              color="info"
              @click="showAdd(item.student)"
              v-if="!item.institution"
              >platzieren</v-btn
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" max-width="400" scrollable>
      <v-card :disabled="saving" :loading="saving">
        <v-system-bar window
          >Insitution zuweisen <v-spacer /><v-btn icon @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-system-bar>
        <v-toolbar flat>
          <v-text-field
            single-line
            dense
            hide-details
            label="Platz suchen"
            v-model="emptyPlacementSearch"
            append-icon="mdi-magnify"
            clearable
          ></v-text-field>
        </v-toolbar>

        <v-card-text class="pa-0 ma-0">
          <v-list>
            <InstitutionItem
              v-for="placement in emptyPlacementsFiltered"
              :key="'placement' + placement.id"
              :value="placement.institution"
              @click="setInstitution(placement)"
            />
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="dialog = false"> Abbrechen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import InstitutionItem from "./InstitutionItem.vue";
import PersonItem from "common/components/PersonItem.vue";
import SchoolYearPicker from "@/components/SchoolYearPicker.vue";

import { comparePeople, searchPerson } from "common/utils/people.js";

export default defineComponent({
  components: { InstitutionItem, PersonItem, SchoolYearPicker },
  data() {
    return {
      loading: false,
      dialog: false,
      filterType: null,
      student: null,
      emptyPlacements: [],
      saving: false,
      headers: [
        {
          text: "Schüler*in",
          value: "student",
        },
        {
          text: "Institution",
          value: "institution",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      items: [],
      emptyPlacementSearch: "",
      schoolYear: null,
      search: "",
    };
  },
  computed: {
    emptyPlacementsFiltered() {
      return this.emptyPlacements.filter(
        (el) =>
          !this.emptyPlacementSearch ||
          this.institutionSearch(el, this.emptyPlacementSearch)
      );
    },
    itemsFiltered() {
      return this.items.filter(
        (el) =>
          el.student &&
          ((this.filterType == null && !el.ignored) ||
            (this.filterType == 0 && el.institution) ||
            (this.filterType == 1 && el.lonely) ||
            (this.filterType == 2 && el.ignored)) &&
          (!this.search ||
            searchPerson(el.student, this.search) ||
            this.institutionSearch(el, this.search))
      );
    },
    title() {
      let result = this.itemsFiltered.length + " Zuteilungen Schüler:innen";
      switch (this.filterType) {
        case 0:
          result += " mit Platz";
          break;
        case 1:
          result += " ohne Platz";
          break;
        case 2:
          result += " ignoriert";
          break;
        default:
        // code block
      }

      return result;
    },
  },
  watch: {
    schoolYear() {
      this.fetchData();
    },
  },
  methods: {
    institutionSearch(el, search) {
      return (
        (el.institution &&
          el.institution.instName &&
          el.institution.instName
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.firstName1 &&
          el.institution.firstName1
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.lastName1 &&
          el.institution.lastName1
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.firstName2 &&
          el.institution.firstName2
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.lastName2 &&
          el.institution.lastName2
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.town &&
          el.institution.town.toLowerCase().includes(search.toLowerCase()))
      );
    },
    async removeStudent(placement) {
      if (
        await this.$root.confirm({
          message: `Soll diese Zuweisung entfernt werden?`,
          color: "error",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiPatch({
          resource: "placement/placement",
          id: placement.id,
          key: "student",
          value: 0,
        });
        this.fetchData();
      }
    },

    async unIgnore(placement) {
      this.saving = true;
      await this.apiDelete({
        resource: "placement/placement",
        id: placement.id,
      });
      this.saving = false;
      this.fetchData();
    },

    async ignore(student) {
      this.saving = true;
      await this.apiPost({
        resource: "placement/placement",
        data: {
          schoolYear: this.schoolYear.id,
          ignoreStudent: true,
          student: student.id,
        },
      });
      this.saving = false;
      this.fetchData();
    },
    async setInstitution(placement) {
      this.saving = true;
      await this.apiPatch({
        resource: "placement/placement",
        id: placement.id,
        key: "student",
        value: this.student.id,
      });
      this.saving = false;
      this.dialog = false;
      this.fetchData();
    },
    showAdd(student) {
      this.student = student;
      this.dialog = true;
    },
    async fetchData() {
      this.loading = true;

      const students = await this.apiList({
        resource: "placement/student",
        query: "schoolYear=" + this.schoolYear.id,
      });

      const placements = await this.apiList({
        resource: "placement/placement",
        query: "schoolYear=" + this.schoolYear.id,
      });

      for (const student of students) {
        const placement = placements.find(
          (el) => el.student && el.student.id == student.id
        );
        if (!placement) {
          placements.push({ student: student, lonely: true });
        }
      }

      this.emptyPlacements = placements.filter((el) => !el.student);

      this.items = placements
        .filter((el) => el.student)
        .sort((a, b) => comparePeople(a.student, b.student));

      this.loading = false;
    },
  },
  created() {},
});
</script>
